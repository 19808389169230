
export enum WeatherType {
  Clear = 0,
  Overcast,
  Rain,
  Thunderstorm,
  Snow,
  Hail,
  Blizzard,
  Sunny,
  Sandstorm,
  Fog,
  Windy,
}
